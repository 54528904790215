var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading || !_vm.formLoaded)?_c('div',[_c('span',{staticClass:"spinner"})]):_vm._e(),(!_vm.loading && _vm.formLoaded)?_c('div',[_c('b-form',{class:{
        'p-3 box-shadow2 rounded': !_vm.isDarkMode,
        'dark-div p-3 box-shadow2 rounded': _vm.isDarkMode
      },on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"id":"group-name","label-for":"input-name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Role Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,1296580240)},[_c('b-form-input',{class:{
            'box-shadow3 bg-light text-dark': !_vm.isDarkMode,
            'bg-dark text-light placeholder-light': _vm.isDarkMode
          },attrs:{"formatter":_vm.formatter,"id":"input-name","type":"text","placeholder":"Enter Role Name","state":_vm.$v.form.name.$dirty ? !_vm.$v.form.name.$error : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-invalid"}},[_vm._v("Please enter Role .")])],1),_c('b-form-group',{attrs:{"id":"group-products","label-for":"input-products"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Permission "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,3400054216)},[_c('multiselect',{ref:"multiselect",class:{
            'box-shadow3': !_vm.isDarkMode,
            darkMode: _vm.isDarkMode
          },attrs:{"tag-placeholder":"Add this as new tag","placeholder":"Select Permissions","label":"text","track-by":"value","options":_vm.permission,"multiple":true,"taggable":true},on:{"tag":_vm.addTag},model:{value:(_vm.form.permission),callback:function ($$v) {_vm.$set(_vm.form, "permission", $$v)},expression:"form.permission"}})],1),_c('b-form-group',{attrs:{"id":"group-name","label-for":"input-name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Description "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,1780825989)},[_c('b-form-textarea',{class:{
            'box-shadow3 bg-light text-dark': !_vm.isDarkMode,
            'bg-dark text-light placeholder-light': _vm.isDarkMode
          },attrs:{"id":"input-name","type":"text","placeholder":"Enter Description If Any"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('b-form-text',{attrs:{"id":"input-description-help"}},[_vm._v("Description must be more than 1 characters")]),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-invalid"}},[_vm._v("Please enter Description .")])],1),_c('b-form-group',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"box-shadow2",attrs:{"type":"submit","size":"sm","variant":_vm.isDarkMode ? 'outline-success' : 'success',"disabled":_vm.loading}},[(_vm.loading)?_c('span',{staticClass:"spinner spinner-white"}):_vm._e(),_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'save']}}),_vm._v("Save ")],1),_c('b-button',{staticClass:"ml-2 box-shadow2",attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-warning' : 'warning',"to":{ path: "/role" }}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'long-arrow-alt-left']}}),_vm._v("Back to list ")],1)],1)],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }