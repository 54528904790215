<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form
        @submit.stop.prevent="onSubmit"
        :class="{
          'p-3 box-shadow2 rounded': !isDarkMode,
          'dark-div p-3 box-shadow2 rounded': isDarkMode
        }"
      >
        <b-form-group id="group-tagCategory" label-for="input-tagCategory">
          <template v-slot:label>
            Tag Category
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            :class="{
              'box-shadow3 bg-light text-dark': !isDarkMode,
              'bg-dark text-light placeholder-light': isDarkMode
            }"
            id="input-tagCategory"
            type="text"
            v-model="form.tagCategory"
            :state="$v.form.tagCategory.$dirty ? !$v.form.tagCategory.$error : null"
            placeholder="Enter Tag Category"
          ></b-form-input>
          <b-form-invalid-feedback id="input-tagCategory-invalid">Please enter Tag Category.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <div class="input wrapper items-center" v-for="(input, tag) in inputs" :key="tag">
            <b-row class="mb-3">
              <b-col>
                <b-row>
                  <b-col>
                    <template labelfor="input-tag"> Tag </template>

                    <b-form-input
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      id="input-tag"
                      :disabled="input._id ? true : false"
                      v-model="input.tag"
                      type="text"
                      placeholder="Enter tag"
                    ></b-form-input>
                  </b-col>

                  <b-col sm="0">
                    <b-button
                      class="mt-4 box-shadow2"
                      @click="remove(tag, input._id)"
                      v-show="tag || (!tag && inputs.length > 1)"
                      :variant="isDarkMode ? 'outline-danger' : 'danger'"
                      >Delete</b-button
                    >
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-button
              :class="{
                'box-shadow2': !isDarkMode,
                'text-white': isDarkMode
              }"
              @click="add(tag)"
              v-show="tag == inputs.length - 1"
              :variant="isDarkMode ? 'outline-primary' : 'primary'"
              >Add Tag</b-button
            >
          </div>
        </b-form-group>

        <b-row>
          <b-col>
            <b-button
              class="box-shadow2"
              type="submit"
              size="sm"
              :variant="isDarkMode ? 'outline-success' : 'success'"
              :disabled="loading"
            >
              <span class="spinner spinner-white" v-if="loading"></span>
              <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button
              class="box-shadow2"
              size="sm"
              :variant="isDarkMode ? 'outline-warning' : 'warning'"
              :to="{ path: `${listUrl}` }"
            >
              <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

export default {
  name: 'TagFormBox',
  props: {
    listUrl: String,
    formType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description'
        }
      ]
    };
  },
  data() {
    return {
      title: '',
      formLoaded: false,
      inputs: [{ tags: '' }],
      form: {
        tagCategory: null
      },
      isLoading: false
    };
  },
  validations() {
    const formValidation = {
      tagCategory: {
        required
      }
    };
    return { form: formValidation };
  },
  mounted() {
    this.$nextTick(async () => {
      // Code that will run only after the entire view has been re-rendered
      if (this.formType === 'new') {
        this.form.tagCategory = this.tagCategory;
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    ...mapState('tags', ['loading', 'tag']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      const tag = {
        tagCategory: this.form.tagCategory,
        tags: this.inputs.map(input => input.tag)
      };
      if (this.formType === 'new') {
        this.$emit('add', { tag });
      } else {
        this.$emit('edit', { tag });
      }
      return false;
    },
    add() {
      this.inputs.push({ tags: '' });
    },
    remove(index, tagId) {
      if (tagId) {
        this.tagDeleted.push(tagId);
      }

      this.inputs.splice(index, 1);
    }
  },
  watch: {
    tagCategory(_newValue) {
      this.form.tagCategory = _newValue;
    },
    tag(_newValue, _oldValue) {
      const { id } = this.tag;
      if (!id) {
        return;
      }
      // Loaded order, assign to form
      this.form.tagCategory = this.tag.tagCategory;
      this.inputs = this.tag.tags.map(tag => ({ tag }));
      this.formLoaded = true;
      this.$v.$touch(); // Set initial validation
      this.$v.$reset(); // Reset $dirty
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
