<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
      <tag-form-box list-url="/tags" :form-type="formType" @add="onAdd" @edit="onEdit"> </tag-form-box>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import TagFormBox from '../../components/TagFormBox.vue';

export default {
  name: 'TagForm',
  components: {
    TagFormBox
  },
  async mounted() {
    if (this.$route.name === 'tags-new') {
      this.formType = 'new';
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;
      await this.getOne({
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: ''
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Tag' : 'Update Tag';
    },
    ...mapState('tags', ['loading'])
  },
  methods: {
    ...mapActions('tags', ['getOne', 'postOne', 'patchOne']),
    onAdd({ tag }) {
      this.postOne({
        tag,
        router,
        redirectUrl: '/tags'
      });
    },
    onEdit({ tag }) {
      this.patchOne({
        id: this.id,
        tag,
        router,
        redirectUrl: '/tags'
      });
    }
  }
};
</script>
