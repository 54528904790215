var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading || !_vm.formLoaded)?_c('div',[_c('span',{staticClass:"spinner"})]):_vm._e(),(!_vm.loading && _vm.formLoaded)?_c('div',[_c('b-form',{class:{
        'p-3 box-shadow2 rounded': !_vm.isDarkMode,
        'dark-div p-3 box-shadow2 rounded': _vm.isDarkMode
      },on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"id":"group-tagCategory","label-for":"input-tagCategory"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tag Category "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,3276217985)},[_c('b-form-input',{class:{
            'box-shadow3 bg-light text-dark': !_vm.isDarkMode,
            'bg-dark text-light placeholder-light': _vm.isDarkMode
          },attrs:{"id":"input-tagCategory","type":"text","state":_vm.$v.form.tagCategory.$dirty ? !_vm.$v.form.tagCategory.$error : null,"placeholder":"Enter Tag Category"},model:{value:(_vm.form.tagCategory),callback:function ($$v) {_vm.$set(_vm.form, "tagCategory", $$v)},expression:"form.tagCategory"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-tagCategory-invalid"}},[_vm._v("Please enter Tag Category.")])],1),_c('b-form-group',_vm._l((_vm.inputs),function(input,tag){return _c('div',{key:tag,staticClass:"input wrapper items-center"},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('b-row',[_c('b-col',[[_vm._v(" Tag ")],_c('b-form-input',{class:{
                      'box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                      'bg-dark text-light placeholder-light': _vm.isDarkMode
                    },attrs:{"id":"input-tag","disabled":input._id ? true : false,"type":"text","placeholder":"Enter tag"},model:{value:(input.tag),callback:function ($$v) {_vm.$set(input, "tag", $$v)},expression:"input.tag"}})],2),_c('b-col',{attrs:{"sm":"0"}},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(tag || (!tag && _vm.inputs.length > 1)),expression:"tag || (!tag && inputs.length > 1)"}],staticClass:"mt-4 box-shadow2",attrs:{"variant":_vm.isDarkMode ? 'outline-danger' : 'danger'},on:{"click":function($event){return _vm.remove(tag, input._id)}}},[_vm._v("Delete")])],1)],1)],1)],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(tag == _vm.inputs.length - 1),expression:"tag == inputs.length - 1"}],class:{
              'box-shadow2': !_vm.isDarkMode,
              'text-white': _vm.isDarkMode
            },attrs:{"variant":_vm.isDarkMode ? 'outline-primary' : 'primary'},on:{"click":function($event){return _vm.add(tag)}}},[_vm._v("Add Tag")])],1)}),0),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"box-shadow2",attrs:{"type":"submit","size":"sm","variant":_vm.isDarkMode ? 'outline-success' : 'success',"disabled":_vm.loading}},[(_vm.loading)?_c('span',{staticClass:"spinner spinner-white"}):_vm._e(),_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'save']}}),_vm._v("Save ")],1)],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"box-shadow2",attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-warning' : 'warning',"to":{ path: ("" + _vm.listUrl) }}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'long-arrow-alt-left']}}),_vm._v("Back to list ")],1)],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }